<template>
  <v-card>
    <!-- toolbar -->
    <v-toolbar flat>
      <v-toolbar-title>{{ name }}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn
        v-if="selected.length > 0"
        color="blue-grey lighten-2 dark"
        class="mx-1"
        dark
        @click="verifyMultipleItems"
        >{{ $t('operations.verify') }} ({{ selected.length }})
      </v-btn>
    </v-toolbar>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        autocomplete="off"
      >
        <template v-slot:label>{{ $t('operations.search') }} </template>
      </v-text-field>
    </v-card-title>
    <!-- table -->
    <v-data-table
      v-model="selected"
      :headers="headers"
      :loading="loading"
      :items="items"
      :single-select="false"
      :single-expand="true"
      :expanded.sync="expanded"
      :search="search"
      item-key="identification_number"
      sort-by="identification_number"
      show-select
      show-expand
    >
      <template v-slot:[`item.options`]="{ item }">
        <div>
          <v-layout justify-end>
            <v-btn
              color="blue-grey lighten-2 dark"
              class="mx-1"
              dark
              small
              @click="verifyItem(item)"
              >{{ $t('operations.verify') }}
            </v-btn>
          </v-layout>
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0 ma-0">
          <v-card class="ma-0 pa-0" flat tile>
            <v-card-title class="h6 white--text grey darken-1 pt-1">
              {{ item.first_name }} {{ item.last_name }}
              <v-spacer></v-spacer>
              {{ item.identification_type_code }}:
              {{ item.identification_number }}
            </v-card-title>
            <v-card-text class="grey lighten-3">
              <v-row class="pt-3">
                <v-col cols="12" sm="6" md="4">
                  <p class="text--primary subtitle-1 font-weight-medium ma-0">
                    {{ $t('fields.birthday') }}
                  </p>
                  <v-input class="input" disabled>
                    <p class="text-body-1 grey--text text--darken-3 ma-0">
                      {{ item.birthday }}
                    </p>
                  </v-input>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <p class="text--primary subtitle-1 font-weight-medium ma-0">
                    {{ $t('fields.years_old') }}
                  </p>
                  <v-input class="input" disabled>
                    <p class="text-body-1 grey--text text--darken-3 ma-0">
                      {{ item.years_old }}
                    </p>
                  </v-input>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <p class="text--primary subtitle-1 font-weight-medium ma-0">
                    {{ $t('fields.sex') }}
                  </p>
                  <v-input class="input" disabled>
                    <p class="text-body-1 grey--text text--darken-3 ma-0">
                      {{ item.sex_name }}
                    </p>
                  </v-input>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <p class="text--primary subtitle-1 font-weight-medium ma-0">
                    {{ $t('fields.address') }}
                  </p>
                  <v-input class="input" disabled>
                    <p class="text-body-1 grey--text text--darken-3 ma-0">
                      {{ item.address }}
                    </p>
                  </v-input>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <p class="text--primary subtitle-1 font-weight-medium ma-0">
                    {{ $t('fields.neighborhood') }}
                  </p>
                  <v-input class="input" disabled>
                    <p class="text-body-1 grey--text text--darken-3 ma-0">
                      {{ item.neighborhood_name }}
                    </p>
                  </v-input>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <p class="text--primary subtitle-1 font-weight-medium ma-0">
                    {{ $t('fields.sector') }}
                  </p>
                  <v-input class="input" disabled>
                    <p class="text-body-1 grey--text text--darken-3 ma-0">
                      {{ item.sector_name }}
                    </p>
                  </v-input>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <p class="text--primary subtitle-1 font-weight-medium ma-0">
                    {{ $t('fields.email') }}
                  </p>
                  <v-input class="input" disabled>
                    <p class="text-body-1 grey--text text--darken-3 ma-0">
                      {{ item.email }}
                    </p>
                  </v-input>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <p class="text--primary subtitle-1 font-weight-medium ma-0">
                    {{ $t('fields.phone') }}
                  </p>
                  <v-input class="input" disabled>
                    <p class="text-body-1 grey--text text--darken-3 ma-0">
                      {{ item.phone }}
                    </p>
                  </v-input>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="grey darken-1 ma-0 pa-0 pt-3">
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </td>
      </template>
    </v-data-table>
    <!-- confirm -->
    <AppDialogConfirm ref="confirm" />
  </v-card>
</template>

<script>
import AppDialogConfirm from '@/components/dialogs/Confirm.vue'
export default {
  name: 'citizens',
  components: { AppDialogConfirm },
  data: () => ({
    name: null,
    url: null,
    url_file: null,
    headers: [],
    formRules: [(v) => !!v || 'Required'],
    items: [],
    boolean: [],
    item: {},
    selected: [],
    expanded: [],
    loading: false,
    loading_text: null,
    isSelecting: false,
    search: null,
    isValid: false
  }),

  created() {
    this.url = 'victims/citizens/'
    this.url_file = 'victims/citizens/file/'
    this.name = this.$t('options.victims.pending')
    this.boolean = [
      { text: this.$t('boolean.yes'), value: true },
      { text: this.$t('boolean.no'), value: false }
    ]
    this.headers = [
      {
        text: this.$t('fields.identification_type'),
        value: 'identification_type_code'
      },
      {
        text: this.$t('fields.identification_number'),
        value: 'identification_number',
        sortable: false
      },
      { text: this.$t('fields.first_name'), value: 'first_name' },
      { text: this.$t('fields.last_name'), value: 'last_name' },
      { text: this.$t('fields.options'), value: 'options', align: 'right' },
      { text: '', value: 'data-table-expand' }
    ]
    this.getItems()
  },

  methods: {
    getItems() {
      this.loading = true
      this.$http.get(`${this.url}?is_verified=0`).then((request) => {
        this.loading = false
        this.items = request.data
      })
    },

    verifyItem(item) {
      let options = { color: 'teal' }
      let title = this.$t('operations.verify').toUpperCase()
      let messaje = this.$t('dialogs.confirm_text')
      this.$refs.confirm.open(title, messaje, options).then((confirm) => {
        if (confirm) {
          this.$http
            .put(`${this.url}?citizen_id=${item.citizen_id}`)
            .then(() => this.getItems())
        }
      })
    },

    verifyMultipleItems() {
      let options = { color: 'teal' }
      let title = this.$t('operations.verify').toUpperCase()
      let messaje = this.$t('dialogs.confirm_text')
      let data = this.selected
        .map(function (i) {
          return i.citizen_id
        })
        .join(',')
      this.$refs.confirm.open(title, messaje, options).then((confirm) => {
        if (confirm) {
          this.$http
            .put(this.url, JSON.stringify({ citizen_id__in: data }))
            .then(() => this.getItems())
        }
      })
    }
  }
}
</script>

<style>
.input .v-input__slot {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
  padding-left: 5px;
}
</style>
